/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-back': {
    width: 21.543,
    height: 13.836,
    viewBox: '0 0 21.543 13.836',
    data: '<path pid="0" d="M7.326 13.666l.944-.944a.577.577 0 00-.008-.825L4.378 8.168h16.587a.577.577 0 00.577-.577V6.244a.577.577 0 00-.577-.577H4.378l3.883-3.729a.577.577 0 00.008-.825L7.325.169a.577.577 0 00-.816 0l-6.34 6.34a.577.577 0 000 .816l6.341 6.341a.577.577 0 00.816 0z" _fill="#08acc0"/>'
  }
})
