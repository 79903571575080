/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-d': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M240 800c-12.288 0-24.576-4.672-33.92-14.08-18.752-18.752-18.752-49.152 0-67.904l222.08-222.08L206.08 273.92c-18.752-18.752-18.752-49.152 0-67.904s49.152-18.752 67.904 0l256 256c18.752 18.752 18.752 49.152 0 67.904l-256 256C264.576 795.328 252.288 800 240 800z" _fill="#08ACC0"/><path pid="1" d="M560 800c-12.288 0-24.576-4.672-33.92-14.08-18.752-18.752-18.752-49.152 0-67.904l222.08-222.08L526.08 273.92c-18.752-18.752-18.752-49.152 0-67.904s49.152-18.752 67.904 0l256 256c18.752 18.752 18.752 49.152 0 67.904l-256 256C584.576 795.328 572.288 800 560 800z" _fill="#08ACC0"/>'
  }
})
