import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'

import {BootstrapVue} from 'bootstrap-vue'
import SvgIcon from 'vue-svgicon'

import '@/icons'

Vue.use(BootstrapVue)
Vue.use(SvgIcon, {tagName: "svg-icon"})

require('@/assets/sass/main.scss')

Vue.prototype.$axios = axios

import './index.css'


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
