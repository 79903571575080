import Vue from 'vue'
import Router from 'vue-router'

import Dev from './views/no'

import LayoutAdmin from './layouts/LayoutAdmin.vue'

Vue.use(Router)

let router = new Router({
	mode: 'history',
	routes: [
		{ 
			path: '/admin', 
			component: LayoutAdmin, 
			children: [
				{path: '/', name: "home", component: ()=>import('./views/home')},
				{path: 'folder', name: "folder", component: Dev},
				{path: 'tasks', name: "tasks", component: ()=>import('./views/tasks')},
				{path: 'tag', name: "tag", component: Dev},
				{path: 'star', name: "star", component: Dev},
				{path: 'question', name: "question", component: Dev},
				{path: 'setting', name: "setting", component: Dev},
			] 
		},		
		
		{ path: "*", redirect: "/admin" }
			
	]
})

export default router
