/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tasks': {
    width: 28.306,
    height: 22.3,
    viewBox: '0 0 28.306 22.3',
    data: '<path pid="0" d="M8.091 8.976a.442.442 0 00-.627 0l-3.926 3.925-2.156-2.156a.442.442 0 00-.625 0l-.627.627a.442.442 0 00-.13.313.442.442 0 00.13.313l3.1 3.1a.442.442 0 00.625 0l4.861-4.869a.442.442 0 000-.626zm-4.557 9.785a1.769 1.769 0 100 3.538 1.769 1.769 0 100-3.538zM8.091.13a.442.442 0 00-.627 0L3.538 4.055 1.382 1.899a.442.442 0 00-.625 0l-.627.627a.442.442 0 00-.13.313.442.442 0 00.13.313l3.1 3.1a.442.442 0 00.625 0l4.861-4.869a.442.442 0 000-.626zm19.773 19.516H11.057a.442.442 0 00-.442.442v.885a.442.442 0 00.442.442h16.807a.442.442 0 00.442-.442v-.885a.442.442 0 00-.442-.442zm0-17.691H11.057a.442.442 0 00-.442.442v.885a.442.442 0 00.442.442h16.807a.442.442 0 00.442-.442v-.885a.442.442 0 00-.442-.442zm0 8.846H11.057a.442.442 0 00-.442.442v.885a.442.442 0 00.442.442h16.807a.442.442 0 00.442-.442v-.885a.442.442 0 00-.442-.443z" _fill="#08acc0"/>'
  }
})
