/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag': {
    width: 28.306,
    height: 22.645,
    viewBox: '0 0 28.306 22.645',
    data: '<path pid="0" d="M27.684 13l-9.028 9.028a2.123 2.123 0 01-3 0l-.079-.079 1-1 .079.079a.708.708 0 001 0L26.684 12a.708.708 0 000-1l-9.372-9.377a.7.7 0 00-.5-.207h-.865L14.532 0h2.28a2.123 2.123 0 011.5.622l9.371 9.371A2.123 2.123 0 0127.684 13zM11.151 1.415H2.123a.708.708 0 00-.708.708v9.028a.7.7 0 00.207.5l9.371 9.371a.708.708 0 001 0l9.028-9.028a.708.708 0 000-1l-9.37-9.371a.7.7 0 00-.5-.207m0-1.415a2.123 2.123 0 011.5.622l9.371 9.371a2.123 2.123 0 010 3L13 22.023a2.123 2.123 0 01-3 0L.622 12.652A2.123 2.123 0 010 11.151V2.123A2.123 2.123 0 012.123 0zM6.369 5.484a.885.885 0 10.885.885.886.886 0 00-.885-.885m0-1.238a2.123 2.123 0 11-2.123 2.123 2.123 2.123 0 012.123-2.123z" _fill="#08acc0"/>'
  }
})
