/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M554.667 554.667v298.666a42.667 42.667 0 01-85.334 0V554.667H170.667a42.667 42.667 0 010-85.334h298.666V170.667a42.667 42.667 0 0185.334 0v298.666h298.666a42.667 42.667 0 010 85.334H554.667z" _fill="#08ACC0"/>'
  }
})
