/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M976.738 892.062L712.862 630.154c53.169-74.83 80.738-169.354 66.953-269.785-23.63-169.354-161.477-303.261-332.8-319.015-232.369-23.63-429.292 173.292-405.661 407.63 15.754 169.354 149.661 309.17 319.015 332.8C460.8 795.57 555.323 768 630.154 714.832l261.908 261.907c11.815 11.816 29.538 11.816 41.353 0l41.354-41.353c11.816-11.816 11.816-31.508 1.97-43.323zm-819.2-480.493c0-139.815 114.216-254.03 254.031-254.03 139.816 0 254.031 114.215 254.031 254.03 0 139.816-114.215 254.031-254.03 254.031-139.816 0-254.032-112.246-254.032-254.03z" _fill="#08ACC0"/>'
  }
})
