/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 20.996,
    height: 18.372,
    viewBox: '0 0 20.996 18.372',
    data: '<path pid="0" d="M10.5 15.419a4.921 4.921 0 114.919-4.919 4.925 4.925 0 01-4.919 4.919zm0-8.53a3.609 3.609 0 103.607 3.611A3.614 3.614 0 0010.5 6.889zM9.186 10.5A1.316 1.316 0 0110.5 9.186a.656.656 0 000-1.312A2.627 2.627 0 007.874 10.5a.656.656 0 001.312 0zM13.3 1.312a.333.333 0 01.308.213l.906 2.411h4.515a.658.658 0 01.656.656V16.4a.658.658 0 01-.656.656H1.968a.658.658 0 01-.656-.656V4.593a.658.658 0 01.656-.656h4.519l.824-2.2a.657.657 0 01.615-.426H13.3M13.3 0H7.927a1.966 1.966 0 00-1.841 1.275l-.508 1.349h-3.61A1.969 1.969 0 000 4.593V16.4a1.969 1.969 0 001.968 1.968h17.06A1.969 1.969 0 0021 16.4V4.593a1.969 1.969 0 00-1.968-1.968h-3.613l-.586-1.558A1.631 1.631 0 0013.3 0z" _fill="#8f9198"/>'
  }
})
