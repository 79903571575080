/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'setting': {
    width: 28,
    height: 29.088,
    viewBox: '0 0 28 29.088',
    data: '<path pid="0" d="M27.294 17.082l-1.912-1.1a11.445 11.445 0 000-2.868l1.912-1.1a1.4 1.4 0 00.648-1.613 14.519 14.519 0 00-3.374-5.841 1.408 1.408 0 00-1.726-.251l-1.91 1.1a11.384 11.384 0 00-2.483-1.436V1.762a1.408 1.408 0 00-1.084-1.37 14.647 14.647 0 00-6.731 0 1.408 1.408 0 00-1.084 1.37v2.206A11.383 11.383 0 007.067 5.4l-1.91-1.1a1.408 1.408 0 00-1.726.251 14.52 14.52 0 00-3.374 5.841 1.4 1.4 0 00.648 1.613l1.912 1.1a11.446 11.446 0 000 2.868l-1.912 1.1a1.4 1.4 0 00-.648 1.613 14.52 14.52 0 003.374 5.841 1.408 1.408 0 001.726.251l1.91-1.1a11.382 11.382 0 002.483 1.436v2.206a1.408 1.408 0 001.084 1.38 14.647 14.647 0 006.731 0 1.408 1.408 0 001.084-1.37v-2.21a11.385 11.385 0 002.483-1.436l1.91 1.1a1.408 1.408 0 001.726-.251 14.52 14.52 0 003.374-5.841 1.4 1.4 0 00-.648-1.613zm-3.84 5.892l-2.716-1.568a10.133 10.133 0 01-4.166 2.408v3.136a12.735 12.735 0 01-5.145 0v-3.137a10.134 10.134 0 01-4.166-2.408l-2.716 1.568a12.668 12.668 0 01-2.576-4.455l2.717-1.568a10.218 10.218 0 010-4.814l-2.717-1.568a12.676 12.676 0 012.576-4.455l2.716 1.569a10.118 10.118 0 014.163-2.407V2.138a12.714 12.714 0 015.145 0v3.136a10.118 10.118 0 014.166 2.409l2.716-1.569a12.675 12.675 0 012.576 4.455l-2.713 1.568a10.217 10.217 0 010 4.814l2.717 1.568a12.667 12.667 0 01-2.576 4.455zM14 8.914a5.63 5.63 0 105.63 5.63A5.636 5.636 0 0014 8.914zm0 9.383a3.753 3.753 0 113.753-3.753A3.758 3.758 0 0114 18.3z" _fill="#08acc0"/>'
  }
})
