/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 10.335,
    height: 10.335,
    viewBox: '0 0 10.335 10.335',
    data: '<path pid="0" data-name="Path 6068" d="M1.061 5.17l4.101 4.101 4.11-4.109" _fill="none" _stroke="#adb9d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
