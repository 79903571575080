<template>
  <div class="ku-header pr-3">
      <div class="d-flex">
        <div id="aside_show" @click="showAside">
            <svg-icon name="arrow-right-d" width="18" original></svg-icon>
        </div>
        <div class="ku-header-search-box d-flex align-items-center tw-flex-1">
            <div class="p-3">
                <svg-icon name="search" width="23" original class="tw-cursor-pointer"></svg-icon>
            </div>
            <input type="text" class="ku-input ml-2 tw-flex-1 ku-keyword font-16" placeholder="Suche nach Bildern … ">
        </div>
      </div>
      <div class="d-flex align-items-center">
          <div class="pl-2 tw-pr-1 sm:tw-pr-4">
              <svg-icon name="bell" width="18" original></svg-icon>
          </div>

          <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret right >
                <template #button-content>
                    <div class="d-flex align-items-center tw-cursor-pointer ku-avatar-box">
                            <div class="px-2">
                                <h4 class="font-16 mb-1">Maria Musterfrau</h4>
                                <p class="text-dark-100 mb-0 font-14">Content Redakteur</p>
                            </div>
                            <div class="px-2">
                                <div class="ku-header-avatar">
                                    <img src="/upload/user1.jpg" alt="">
                                </div>
                            </div>
                            <div>
                                <svg-icon name="arrow-down" width="10" original></svg-icon>
                            </div>               
                    </div>
                </template>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#">Change Password</b-dropdown-item>
                <b-dropdown-item href="#">Log out</b-dropdown-item>
            </b-dropdown>
          
      </div>
  </div>
</template>

<script>
export default {
    name: "KuHeader",    
    data(){
        return{            
        }
    },
    methods:{    
        showAside(){
            let mm =  document.getElementsByClassName('ku-aside')[0]          
            mm.classList.add('active')
        }    
    },
    mounted(){
        window.addEventListener('click', function(e){
            let mm =  document.getElementsByClassName('ku-aside')[0]
            if(e.target.closest('#aside_show')){
                if(!mm.classList.contains('active')) mm.classList.add('active')
            }else{
                if(mm.classList.contains('active')) mm.classList.remove('active')
            }
        })
    }
}
</script>