/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell': {
    width: 17.758,
    height: 20.295,
    viewBox: '0 0 17.758 20.295',
    data: '<path pid="0" d="M8.879 19.026a1.27 1.27 0 01-1.268-1.27H6.343a2.537 2.537 0 105.074 0h-1.269a1.27 1.27 0 01-1.269 1.27zm8.3-5.755c-1.108-1.055-1.956-2.16-1.956-5.9A6.185 6.185 0 009.514 1.33v-.7a.634.634 0 00-1.268 0v.7a6.184 6.184 0 00-5.71 6.039c0 3.743-.848 4.847-1.956 5.9A1.845 1.845 0 00.134 15.3a1.891 1.891 0 001.771 1.19h13.951a1.892 1.892 0 001.769-1.19 1.844 1.844 0 00-.446-2.029zm-1.323 1.95H1.905a.6.6 0 01-.449-1.031c1.38-1.315 2.349-2.79 2.349-6.821a4.956 4.956 0 015.074-4.832 4.956 4.956 0 015.076 4.832c0 4.017.96 5.5 2.35 6.821a.6.6 0 01-.449 1.031z" _fill="#adb9d8"/>'
  }
})
