/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-o': {
    width: 28.306,
    height: 18.871,
    viewBox: '0 0 28.306 18.871',
    data: '<path pid="0" d="M25.945 7.863h-2.357V5.5a2.359 2.359 0 00-2.359-2.359h-7.862L10.222 0H2.359A2.359 2.359 0 000 2.359v14.153a2.359 2.359 0 002.359 2.359h18.923a3.933 3.933 0 003.371-1.909l3.315-5.527a2.36 2.36 0 00-2.023-3.572zM2.359 1.573H9.57l3.145 3.145h8.515a.786.786 0 01.786.786v2.359H8.412a3.933 3.933 0 00-3.371 1.909l-3.468 5.78V2.359a.786.786 0 01.786-.786zm24.261 9.053l-3.32 5.527a2.359 2.359 0 01-2.018 1.147H2.2l4.031-6.718a2.359 2.359 0 012.023-1.145h17.69a.786.786 0 01.674 1.191z" _fill="#08acc0"/>'
  }
})
