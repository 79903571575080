<template>
  <div class="ku-aside">
      <!-- Logo -->
      <div class="pt-3 d-flex justify-content-center">
        <svg-icon name="logo" original height="70"></svg-icon>
      </div>
      <!-- Menu Item -->
      <div class="mt-3">
          <div v-for="(item, ii) in menu" :key="ii" class="px-3 py-2">
              <div class="ku-menu-item text-center py-2" :class="{active: active==item.link}" @click="goNav(item.link)">
                <svg-icon :name="item.icon" original width="28"></svg-icon>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {aside} from "@/config/menu.json"
export default {
    name: "KuAside",    
    data(){
        return{
            menu: aside,
            active: "home"
        }
    },
    methods:{
        goNav(name){
            this.active=name
            if (name!=this.$route.name) this.$router.push({name})            
        }
    },
    watch:{
        "$route.name"(to){
            if (to!=this.active) this.active = to;
        }
    }    
}
</script>