/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 28,
    height: 26.799,
    viewBox: '0 0 28 26.799',
    data: '<path pid="0" d="M26.565 8.977l-7.646-1.115L15.5.933a1.676 1.676 0 00-3 0L9.08 7.862 1.434 8.977a1.676 1.676 0 00-.926 2.858l5.532 5.391-1.308 7.615a1.674 1.674 0 002.428 1.764L14 23.013l6.84 3.6a1.675 1.675 0 002.428-1.764l-1.308-7.624 5.532-5.391a1.676 1.676 0 00-.926-2.858zm-6.4 7.662l1.455 8.474-7.62-4-7.615 4 1.455-8.478-6.165-6.013 8.515-1.235L14 1.667l3.81 7.72 8.515 1.235-6.16 6.008z" _fill="#08acc0"/>'
  }
})
