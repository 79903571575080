/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checked': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M417.185 768c-9.481 0-18.963-3.793-26.548-11.378L144.119 510.104c-15.17-15.17-15.17-37.926 0-53.097 15.17-15.17 37.925-15.17 53.096 0l219.97 219.97 409.6-409.6c15.17-15.17 37.926-15.17 53.096 0 15.17 15.171 15.17 37.927 0 53.097L443.733 756.622C436.148 764.207 426.667 768 417.185 768z" _fill="#fff"/>'
  }
})
