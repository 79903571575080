/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 28.001,
    height: 24.502,
    viewBox: '0 0 28.001 24.502',
    data: '<path pid="0" d="M27.837 10.782L15.101.389a1.759 1.759 0 00-2.209 0L.161 10.782a.438.438 0 00-.063.615l.552.679a.438.438 0 00.613.065l2.235-1.826v13.291a.875.875 0 00.875.875h7a.875.875 0 00.875-.875v-7l3.5.016v7.005a.875.875 0 00.875.875l7-.018a.875.875 0 00.875-.875V10.315l2.235 1.826a.438.438 0 00.615-.063l.552-.679a.438.438 0 00-.066-.616zm-5.091 11.956l-5.251.016v-7.01a.878.878 0 00-.872-.875l-5.251-.015a.875.875 0 00-.878.875v7.009H5.246V8.886l8.751-7.142 8.749 7.142z" _fill="#08acc0"/>'
  }
})
