/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-o': {
    width: 28.306,
    height: 28.306,
    viewBox: '0 0 28.306 28.306',
    data: '<path pid="0" d="M14.153 18.947a1.6 1.6 0 101.6 1.6 1.6 1.6 0 00-1.6-1.6zm.438-1.37h-.913a.685.685 0 01-.685-.685v-.022c0-4.014 4.419-3.631 4.419-6.13 0-1.142-1.014-2.295-3.278-2.295a3.772 3.772 0 00-3.379 1.637.683.683 0 01-.927.136l-.75-.522a.684.684 0 01-.151-.98 5.989 5.989 0 015.207-2.553c2.986 0 5.561 1.7 5.561 4.578 0 3.847-4.419 3.644-4.419 6.13v.022a.685.685 0 01-.685.685zm-.438-15.751A12.327 12.327 0 111.826 14.153 12.328 12.328 0 0114.153 1.826m0-1.826a14.153 14.153 0 1014.153 14.153A14.155 14.155 0 0014.153 0z" _fill="#08acc0"/>'
  }
})
