<template>
  <div class="ku-main">
      <Aside />
      <div class="ku-content">
        <Header />
        <div class="px-3 ku-body">
          <div class="mt-3">
           <router-view />      
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Aside from './components/Aside'
import Header from './components/Header'
export default {
    name: "LayoutUser",
    components: {Aside, Header}
}
</script>