/* eslint-disable */
require('./arrow-back')
require('./arrow-down')
require('./arrow-right-d')
require('./bell')
require('./camera')
require('./checked')
require('./folder-o')
require('./home')
require('./logo')
require('./plus')
require('./question-o')
require('./search')
require('./setting')
require('./star')
require('./tag')
require('./tasks')
