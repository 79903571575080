/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 72.195,
    height: 65.308,
    viewBox: '0 0 72.195 65.308',
    data: '<g data-name="Group 1689" _fill="#08acc0"><path pid="0" data-name="Rectangle 708" d="M21.605 0h5.787v5.787h-5.787z"/><path pid="1" data-name="Rectangle 709" d="M14.951 8.036h3.536v3.537h-3.536z"/><path pid="2" data-name="Path 6831" d="M37.168 10.223h4.627V3.281H30.22v11.57h6.948z"/><path pid="3" data-name="Path 6832" d="M38.711 11.766v12.023h6.264v5.657H56.39v-17.68z"/><path pid="4" data-name="Path 6833" d="M12.893 23.79h8.712v-9.644H0v21.6h12.893z"/><path pid="5" data-name="Rectangle 710" d="M16.459 27.355h24.952v24.953H16.459z"/></g><text transform="translate(23.195 56.308)" _fill="#fff" font-size="27" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">Am</tspan></text>'
  }
})
